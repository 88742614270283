import React from "react";
import {Router, Routes, Route } from 'react-router-dom'
import "./App.css";
import NavBar from "./components/navBar/NavBar";
import CustomCarousel from "./components/carousel/Carousal";
import TitleMessage from "./components/title-message/TitleMessage";
import Product from "./views/Product/Product";
import { Parallax } from "react-parallax";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Slide from "react-reveal/Slide";
import Container from "react-bootstrap/Container";
import Skills from "./views/skills/Skills";
import Blog from "./views/blog-section/BlogSection";
import Projects from "./views/projects/projects";
import Contact from "./views/contact/Contact";
import Footer from "./components/footer/Footer";




//Create a app.js that uses Navbar, Project, Contact and Footer
function App() {
  return (
    <div className="App" style={{ position: "relative" }}>
      <NavBar />
      <CustomCarousel />
      <TitleMessage />
      <Parallax
        blur={{ min: -30, max: 30 }}
        bgImage={require("./assets/img/parallex/background.webp")}
        bgImageAlt="" 
        strength={-200}
      >
      </Parallax>
      <div>
        <Container className="container-box rounded">
          <Fade duration={500}>
            <hr />
            <Contact />
          </Fade>
        </Container>
      </div>
      <hr />
      <Footer />
    </div>
  );
}

export default App;
